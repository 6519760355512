import {useEffect, useState} from 'react';
import {Dimensions as NativeDimensions} from 'react-native';

interface BothDimensions {
  screen: Dimensions;
  window: Dimensions;
}

interface Dimensions {
  height: number;
  width: number;
}

type Getter = (screen: Dimensions, window: Dimensions) => Dimensions;

type Setter = (dimensions: Dimensions) => void;

type Type = 'screen' | 'window';

type Unsubscribe = () => void;

const getDimensions = (type: Type, getDimensions: Getter): Dimensions => {
  const [dimensions, setDimensions]: [Dimensions, Setter] = useState(
    (): Dimensions => NativeDimensions.get(type)
  );
  useEffect((): Unsubscribe => {
    const handleDimensionsChange = ({window, screen}: BothDimensions): void => {
      const newDimensions: Dimensions = getDimensions(window, screen);
      if (
        dimensions.height !== newDimensions.height ||
        dimensions.width !== newDimensions.width
      ) {
        setDimensions({...newDimensions});
      }
    };
    const listener = NativeDimensions.addEventListener(
      'change',
      handleDimensionsChange
    );

    return (): void => {
      listener.remove();
    };
  }, [NativeDimensions]);
  return dimensions;
};

export const useScreenDimensions = (): Dimensions => {
  return getDimensions('screen', (_, screen) => screen);
};

export const useWindowDimensions = (): Dimensions => {
  return getDimensions('window', window => window);
};

export const useDimensions = (): BothDimensions => {
  const screen = useScreenDimensions();
  const window = useWindowDimensions();
  return {screen, window};
};
