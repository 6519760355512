import * as React from 'react';
import {Text, TouchableBox} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {useTranslation} from '@youtoken/ui.service-i18n';

type AgreementLinkProps = {
  url: string;
} & React.ComponentProps<typeof TouchableBox>;

export const AgreementLink: React.FC<AgreementLinkProps> = ({
  url,
  ...props
}) => {
  const handleClick = React.useCallback(() => {
    if (url) {
      SHARED_ROUTER_SERVICE.navigate('Agreement', {url}); // todo fix
    }
  }, [url]);

  const {t} = useTranslation();

  return (
    <TouchableBox
      onPress={handleClick}
      flexDirection="row"
      alignItems="center"
      {...props}
    >
      <Icon name="document" color="$text-05" />
      <Text variant="$body-02-medium-accent" color="$text-05" ml={3}>
        {t('common.elements.agreement_link')}
      </Text>
    </TouchableBox>
  );
};
