import * as React from 'react';
import {Platform} from 'react-native';
import BottomSheet, {BottomSheetModal} from '@gorhom/bottom-sheet';
import {useIsKeyboardVisible} from '../useIsKeyboardVisible';

// This is fix for Android issue with keyboard closing bottom sheet: https://github.com/gorhom/react-native-bottom-sheet/issues/1602
// TODO: Remove it all after update @gorhom/bottom-sheet to v5 or higher

export const useEnableDismissOnClose = (
  bottomSheetRef: React.MutableRefObject<BottomSheetModal | BottomSheet | null>
): [boolean, () => void] => {
  const isKeyboardVisible = useIsKeyboardVisible();
  const [enableDismissOnClose, setEnableDismissOnClose] = React.useState(true);

  const onBackdropPress = React.useCallback(() => {
    if (!enableDismissOnClose && isKeyboardVisible) {
      setEnableDismissOnClose(true);
    }
    bottomSheetRef.current?.close();
  }, [isKeyboardVisible, bottomSheetRef.current, enableDismissOnClose]);

  React.useEffect(() => {
    if (Platform.OS === 'android') {
      setEnableDismissOnClose(!isKeyboardVisible);
    }
  }, [isKeyboardVisible]);

  return [enableDismissOnClose, onBackdropPress];
};
